require ('../index');
$('#formContato').ajaxForm({
    dataType: 'json',
    data: { objeto: 'Email', metodo: 'Contato' },
    beforeSubmit: function(a,f,o) {
        $('#formContato').addClass('was-validated');
        if(!$('#formContato').get(0).checkValidity()){
            return false;
        }
        $('#formContato button').attr('disabled','disabled');
        $('#formContato button').html('<img src="/img/carregando.gif" /> Enviando...');
    },
    success: function(data) {
        if(data.success){
            alert(data.msg);
            location.reload();
        }else{
            $('#formContato button').removeAttr('disabled');
            $('#formContato button').html('Enviar');
            alert(data.msg == 'undefined' ? 'Erro ao reenviar email.' : data.msg);
        }
    }
});
/*if($("#selectUfOrcamento").val() != ''){
    CarregarMunicipios($("#selectUfOrcamento"),'selectMunicipioOrcamento',false);
}*/
$('#formOrcamento').ajaxForm({
    dataType: 'json',
    data: { objeto: 'Email', metodo: 'Orcamento' },
    beforeSubmit: function(a,f,o) {
        $('#formOrcamento').addClass('was-validated');
        if(!$('#formOrcamento').get(0).checkValidity()){
            return false;
        }
        $('#formOrcamento button').attr('disabled','disabled');
        $('#formOrcamento button').html('<img src="/img/carregando.gif" /> Enviando...');
    },
    success: function(data) {
        if(data.success){
            alert(data.msg);
            location.reload();
        }else{
            $('#formOrcamento button').removeAttr('disabled');
            $('#formOrcamento button').html('Enviar');
            alert(data.msg == 'undefined' ? 'Erro ao reenviar email.' : data.msg);
        }
    }
});

/// carousel para modulo depoimentos
$('.owl-carousel-depoimentos').owlCarousel({
    loop: true,
    autoplay: false,
    items:1,
    nav:true
})

/// carousel para modulo parceiro
$('.owl-carousel-parceiros').owlCarousel({
    loop:true,
    margin:10,
    responsiveClass:true,
    responsive:{
        0:{
            items:1,
            nav:true
        },
        600:{
            items:3,
            nav:false
        },
        1000:{
            items:5,
            nav:true,
            loop:false
        }
    }
})